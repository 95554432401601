import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from '../header';
import Footer from '../footer';

import styles from '../../../sass/style.scss';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, colorTheme, bgColorTheme}) => {
  const stylesheets = [
    {
      key: "dev-icons",
      rel: "stylesheet",
      href: "https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css"
    }
  ]

  return (
    <>
        <div className={`page`}>
          <Helmet title="Urbity">
            { 
              stylesheets.map((style) => (
                <link rel={style.rel} key={style.key} href={style.href} />
              ))
            } 
          
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" integrity="sha256-mmgLkCYLUQbXn0B1SRqzHar6dCnv9oZFPEC1g1cwlkk=" crossorigin="anonymous" />
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7245603.js" />
          </Helmet>

          <Header colorTheme={ colorTheme } bgColorTheme={ bgColorTheme } />
          {children}
          <Footer />
        </div>
      </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string
};

Layout.defaultProps = {
  color: "primary",
  bgColor: "white"
};

export default Layout
