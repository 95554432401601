import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      strapiFooter {
        Tagline
        Offices {
          id
          Address
          StateZIP
          Email
        }
        SocialLink {
          id
          Name
          Link
          Icon
        }
      }
    }
  `)

  return (
    <footer>
      <div className="content extra wrap space-between">
        <div className="footnote column" style={ { width: '500px' } }>
          <h5>Urbity</h5>
          <p className="description">{ data.strapiFooter.Tagline }</p>
          <p>&copy; { new Date().getFullYear() } Urbity, LLC. All rights reserved. </p>
          <p>Built with <i className="fas fa-heart" /> in C <i className="fas fa-bus" /></p>
        </div>
        <div className="contact column">
          <h5 className="header">Offices</h5>
          <div className="grid col-2 mob-wrap">
            {
              data.strapiFooter.Offices.map((office) => (
                <div className="office column" key={ office.id }>
                  <div className="address">{ office.Address }</div>
                  <div className="zip">{ office.StateZIP }</div>
                  { office.Email && (<div className="address"><a href={`mailto:${office.Email}`}>{ office.Email }</a></div>) }
                </div>
              ))
            }
          </div>
          <div className="social-links wrap">
            {
              data.strapiFooter.SocialLink.map((link) => (
                <a className="link" key={ link.id } href={ link.Link }><i className={ link.Icon } /></a>
              ))
            }
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
