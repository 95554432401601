import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import Logo from '../logo'

const Header = ({ colorTheme, bgColorTheme }) => {
  const [menuToggle, setMenuToggle] = useState(false)

  const getToggledClass = () => {
    return menuToggle ? "open" : ""
  }

  return (
    <header className={ bgColorTheme }>
      <nav className={`content inter ${colorTheme}`}>
        <div className={`hamburger ${getToggledClass()}`} onClick={ () => setMenuToggle(!menuToggle) }>
          <i className="fas fa-bars" />
          <span>Urbity</span>
        </div>

        <div className={`menu ${getToggledClass()}`}>
          <Link
            activeClassName="current"
            to="/"
          >Urbity</Link>

          <Link
            activeClassName="current"
            to="/big-idea"
          >Big Idea</Link>

          <Link
            activeClassName="current"
            to="/work"
          >Work</Link>

          <Link
            activeClassName="current"
            to="/stories"
          >Stories</Link>

          <Link
            activeClassName="current"
            to="/hire-us"
          >Hire Us</Link>
        </div>

        <Link 
          key="logo" 
          to="/" 
          className="logo" 
          style={{ marginRight: 0, marginLeft: 'auto' }}
          >
            <Logo />
        </Link>
      </nav>
    </header>
  )
}

Header.defaultProps = {
  colorTheme: "primary",
  bgColorTheme: "white"
}

Header.propTypes = {
  colorTheme:  PropTypes.string,
  bgColorTheme: PropTypes.string
}

export default Header;
